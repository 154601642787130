<div class="card card--no-padding">
  <div class="subscription-detail-container__wrapper">
    <div class="subscription-detail-container__part">
      <div class="subscription-detail-container__header">
        <div class="subscription-detail-container__header-flex">
          <h3>{{ 'Service.ActiveServices' | translate }}</h3>
          <div class="tags">
            <div *ngFor="let tag of service?.tags" class="tag">{{ tag }}</div>
          </div>
        </div>
        <div v-if="service?.subscriptionId">
          <port-visualiser
            [subscriptionId]="service?.subscriptionId"
            [barsWillRoute]="false"
            (onSelected)="OnClickBar($event)"
            [highlightedSubscriptionId]="activeSubscription?.subscriptionId"
          >
          </port-visualiser>
        </div>
      </div>
      <div class="subscription-detail-container__list">
        <div
          class="subscription-detail-container__item"
          [ngClass]="{'active': sub.product.tag.startsWith('MSC')? activeSubscription?.subscriptionId === sub.subscriptionId  : activeSubscription?.portSubscriptionInstanceId === sub.portSubscriptionInstanceId }"
          *ngFor="let sub of services first as isFirst"
          (click)="setActiveSubscription(sub)"
        >
          <div class="subscription-detail-container__item-left" *ngIf="sub.product.productType !== 'Firewall'">
            <div
              class="subscription-detail-container__icon subscription-detail-container__icon--{{ sub.product.productType | lowercase }}"
            >
              <svg-icon src="assets/images/icons/{{ sub.product.productType | lowercase }}.svg"> </svg-icon>
            </div>
            <span class="subscription-detail-container__item-title">{{ sub.presentableName }}</span>
          </div>
          <div class="subscription-detail-container__item-left" *ngIf="sub.product.productType === 'FW'">
            <div class="subscription-detail-container__icon subscription-detail-container__icon--ip">
              <svg-icon src="assets/images/icons/ip.svg"> </svg-icon>
              <span class="subscription-detail-container__icon-overlap-square">
                <svg-icon src="assets/images/icons/fw.svg" class="subscription-detail-container__overlap-icon">
                </svg-icon>
              </span>
            </div>
            <span class="subscription-detail-container__item-title">{{ sub.presentableName }}</span>
          </div>
          <div class="subscription-detail-container__item-right">
            <span *ngIf="!sub.product.tag.startsWith('MSC')">{{ sub.serviceSpeed * 1000000 | readable_size }}</span>
            <span *ngIf="sub.product.tag.startsWith('MSC')">-</span>
            <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="subscription-detail-container__part subscription-detail-container__part--right">
      <div *ngIf="activeSubscription" class="subscription-detail-container__animate">
        <service-summary
          [service]="activeSubscription"
          [showTraffic]="true"
          [vlanranges]="activeSubscriptionVlanranges"
          [portId]="activeSubscription.product.productType === 'Port' ? activeSubscription.subscriptionId : null"
        >
        </service-summary>
      </div>
    </div>
  </div>
</div>
