import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogIPPrefixesComponent } from "../../components/dialogs/dialog-ip-prefixes/dialog-ip-prefixes";
import { DialogIPPrefixesRequestComponent } from "../../components/dialogs/dialog-ip-prefixes-request/dialog-ip-prefixes-request";
import { DialogIPPrefixesAddComponent } from "../../components/dialogs/dialog-ip-prefixes-add/dialog-ip-prefixes-add";
import { Instance } from "../../components/models/instance";
import { MatomoTracker } from "ngx-matomo";
import { AuthService } from "../../services/AuthService";
import { ApiHelper } from "../../helpers/apihelper";
import { SubscriptionService } from "../../services/subscriptionservice";
import { SubscriptionDetails } from "../../helpers/types";
import { Sort } from "@angular/material/sort";
import { DialogAuthComponent } from "../../components/dialogs/dialog-auth/dialog-auth";
import { Router } from "@angular/router";
import { DialogSelfserviceReplicated } from "../../components/selfservice-replicated/dialog-selfservice/dialog-selfservice";
import { Apollo, ApolloBase } from "apollo-angular";
import { SUBSCRIPTIONS_OF_TYPE_QUERY, SUBSCRIPTION_QUERY } from "../../graphql/domain/subscription";
import { CustomerWithSubscriptionIds } from "../../graphql/custom-types";
import { InstanceFactory } from "../../components/models/base/instancefactory";
import { LIST_QUERIES } from "../../graphql/replication/subscription-list";
import { ProductType } from "../../helpers/enums/productType";
import { ENV } from "../../app/app.runtime";

@Component({
  selector: "page-ip-prefixes",
  templateUrl: "ip-prefixes.html",
  styleUrls: ["ip-prefixes.scss"],
})
export class IPPrefixesPageReplicated implements OnInit {
  public subscriptions = [];
  public sortedData = [];
  public subscriptionsMeta = {};
  public plannedSubscriptions = [];
  public overflowMenuIPActive: boolean;
  private replicatedData: ApolloBase;

  constructor(
    public dialog: MatDialog,
    private matomo: MatomoTracker,
    public auth: AuthService,
    public subscriptionService: SubscriptionService,
    public api: ApiHelper,
    private apolloProvider: Apollo,
  ) {
    this.replicatedData = this.apolloProvider.use("replicatedData");
  }

  ngOnInit() {
    if (!ENV.REPLICATION_ENABLED) {
      console.warn("Accessing replicated IP prefixes page");
    }

    this.matomo.trackPageView();
    this.auth.userLoaded.subscribe((d) => {
      this.fetch();
    });
    if (!this.auth.state.loading) {
      this.fetch();
    }
  }

  fetch() {
    this.subscriptions = [];
    this.replicatedData
      .query<any>({
        query: LIST_QUERIES[ProductType.IPPrefixes],
        variables: { customer: localStorage.getItem("viewingCustomerGUID") },
      })
      .subscribe(({ data }) => {
        const prefixes = data.ipPrefixList.page;
        this.plannedSubscriptions = prefixes.filter((prefix) => prefix.ipPrefix.state === 3);
        this.subscriptions = prefixes.filter((prefix) => prefix.ipPrefix.state !== 3);
        this.sortedData = [...this.subscriptions];
      });

    ["autoMitigation"].forEach((type) => {
      if (this.auth.hasPendingStrongAction(type)) {
        this.auth.clearPendingStrongAction(type);
        const ssState = this.auth.getSelfserviceState();
        this.openSelfserviceDialog(ssState.subscription);
      }
    });
  }

  ipPrefixVersion(subscription) {
    return subscription?.ipPrefix?.afi ? "IPv" + subscription?.ipPrefix?.afi : "";
  }

  userCanAddPrefixes() {
    return this.auth?.hasRole(["Infraverantwoordelijke"]) && this.auth?.isSelfServiceEnabled;
  }

  userCanEditPrefixes(sub) {
    return (
      this.userCanAddPrefixes() &&
      this.auth?.isCurrentOrganisation(sub.customerId) &&
      !sub?.ipPrefix?.prefix?.startsWith("145.98")
    );
  }

  sortData(sort: Sort) {
    const data = this.sortedData;

    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((subA, subB) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "description":
          return this.compare(subA.description, subB.description, isAsc);
        case "prefix":
          let prefixA = subA?.ipPrefix?.prefix;
          let prefixB = subB?.ipPrefix?.prefix;
          if (prefixA === undefined) {
            prefixA = "";
          }
          if (prefixB === undefined) {
            prefixB = "";
          }
          // convert : and / to .
          prefixA = prefixA.replace(":", ".").replace("/", ".");
          prefixB = prefixB.replace(":", ".").replace("/", ".");

          prefixA = prefixA
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");
          prefixB = prefixB
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");

          // remove non numeric data
          prefixA = prefixA.replace(/\D/g, "");
          prefixB = prefixB.replace(/\D/g, "");

          prefixA = Number(prefixA);
          prefixB = Number(prefixB);

          return this.compare(prefixA, prefixB, isAsc);
        case "startdate":
          return this.compare(subA?.startDate, subB?.startDate, isAsc);
        case "version":
          const versionA = subA?.ipPrefix?.afi;
          const versionB = subB?.ipPrefix?.afi;

          return this.compare(versionA, versionB, isAsc);
        case "in_use":
          const inuseA = subA?.ipSubscriptions?.length;
          const inuseB = subB?.ipSubscriptions?.length;

          return this.compare(inuseA, inuseB, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDialog(subscriptions, linkedSubscriptions) {
    const dialogRef = this.dialog.open(DialogIPPrefixesComponent, {
      data: {
        subscriptions,
        linkedSubscriptions,
        editModus: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.forceScrollTop === true) {
        window.scrollTo(0, 0);
      }
    });
  }

  openAddDialog(subscription) {
    const dialogRef = this.dialog.open(DialogIPPrefixesComponent, {
      data: {
        subscriptions: subscription,
        linkedSubscriptions: subscription.availableIpSubscriptions,
        editModus: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.forceScrollTop === true) {
        window.scrollTo(0, 0);
      }
    });
  }

  openRequestPrefixDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIPPrefixesRequestComponent, {
      data: {},
      disableClose: true,
    });
    return false;
  }

  openAddPrefixDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIPPrefixesAddComponent, {
      data: {},
      disableClose: true,
    });
    return false;
  }

  openSelfserviceDialog(subscription) {
    const roleCheckResult = this.auth.checkRoleAccess("IP_PREFIX", "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          selfserviceState: { subscription },
          initialAction: "autoMitigation",
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogSelfserviceReplicated, {
        data: {
          type: "autoMitigation",
          subscription,
        },
      });

      dialogRef.componentInstance.close.subscribe((event: string) => {
        if (event === "refresh") {
          this.subscriptions = [];
          this.fetch();
        }
      });
    }
  }
}
