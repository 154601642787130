import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  AggspSubscriptionDetailsGQL,
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { StorageHelper } from "../../../../helpers/storage/storagehelper";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "aggsp-subscription",
  templateUrl: "aggsp-subscription.html",
})
export class AggspSubscriptionComponent extends GenericSubscriptionComponent<
  AggspSubscriptionDetailsGQL,
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: AggspSubscriptionDetailsGQL,
  ) {
    super(api, auth, dialog, route);
    this.subscriptionChange.subscribe((subscription: AggspSubscriptionDetailsType) =>
      this.onSubscriptionChange(subscription),
    );
  }

  protected onQuerySuccess(result: ApolloQueryResult<AggspSubscriptionDetailsQuery>): AggspSubscriptionDetailsType {
    return result.data.aggspDetails as AggspSubscriptionDetailsType;
  }

  private onSubscriptionChange(subscription: AggspSubscriptionDetailsType) {
    this.portSubscriptionName =
      subscription.portSubscription?.customerDescription ?? subscription.portSubscription?.description;
    this.linkPortSubscription = subscription.portSubscription?.customerId === StorageHelper.currentUser;
  }
}
