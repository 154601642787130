<navigation [activeItem]="1" [requestsBubble]="3" [detailData]="detailData" [terminated]="subscriptionTerminated" />

<div class="container">
  <div class="row">
    <div class="col-lg-3 col--flex">
      <shortcut-menu *ngIf="!subscriptionTerminated" [subscription]="subscription" [messages]="activities" />
      <terminated-card *ngIf="subscriptionTerminated" [date]="subscription?.endDate * 1000 | date:'y-MM-dd hh:mm'" />
    </div>
    <div class="col-lg-9">
      <div class="card">
        <div class="card__top-center">
          <h3 *ngIf="productType === 'Port'">{{ 'PortDetails' | translate }}</h3>
          <h3 *ngIf="productType !== 'Port'">{{ 'ServiceDetails' | translate }}</h3>
          <div class="card__top-actions">
            <notification-button
              *ngIf="subscription && !subscriptionTerminated && productType !== 'Wireless'"
              (click)="openNotificationSettingsDialog()"
              [impactSetting]="impactSetting"
            />
            <div
              *ngIf="auth?.isCurrentOrganisation(subscription?.customerId) && subscription && !subscriptionTerminated"
              class="button button--secondary"
              (click)="openIncidentDialog($event)"
            >
              {{ 'Services.Fulfilment.ReportProblem' | translate }}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="productType === 'Port'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription?.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscription?.md_references.length > 0">
                <td>{{ 'ServiceElement.References' | translate }}</td>
                <td>
                  <div *ngFor="let reference of subscription?.md_references">{{ reference }}</div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.PortID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.PortSpeed' | translate }}</td>
                <td>{{ subscription?.portSpeed * 1000000 | readable_size }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.PortMode' | translate }}</td>
                <td>{{ subscription?.portMode }}</td>
              </tr>
              <tr *ngIf="subscription?.autoNegotiation">
                <td>{{ 'ServiceElement.Autonegotiation' | translate }}</td>
                <td>{{ subscription?.autoNegotiation | enabled_or_value }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.L2MTU' | translate }}</td>
                <td>9100</td>
              </tr>
              <tr *ngIf="subscription?.portSubscription">
                <td>{{ 'ServiceElement.PhysicalPort' | translate }}</td>
                <td>
                  <a
                    *ngIf="linkPortSubscription"
                    [routerLink]="['/subscription/Port/'+subscription?.portSubscription]"
                    class="link"
                    >{{ portSubscriptionName }}</a
                  >
                  <span *ngIf="!linkPortSubscription">{{ portSubscriptionName }}</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr *ngIf="!subscriptionTerminated">
                <td>{{ 'ServiceElement.City' | translate }}</td>
                <td>
                  <span *ngIf="subscription?.address"
                    >{{ subscription?.address.city }} ({{ subscription?.surfLocationCode }})</span
                  >
                </td>
              </tr>
              <tr *ngIf="!subscriptionTerminated">
                <td>{{ 'ServiceElement.Address' | translate }}</td>
                <td>
                  <span *ngIf="subscription?.address"
                    >{{ subscription?.address.street }} {{ subscription?.address.number }} {{ subscription?.address.city
                    }}</span
                  >
                </td>
              </tr>
              <tr *ngIf="subscription?.patchPosition && !subscriptionTerminated">
                <td>{{ 'ServiceElement.PatchPosition' | translate }}</td>
                <td>{{ subscription?.patchPosition }}</td>
              </tr>
              <tr *ngIf="!subscriptionTerminated">
                <td>{{ 'ServiceElement.InterfaceType' | translate }}</td>
                <td>{{ subscription?.ieeeInterfaceType }}</td>
              </tr>
              <tr *ngIf="subscription?.fiberType && !subscriptionTerminated">
                <td>{{ 'ServiceElement.FiberConnectorType' | translate }}</td>
                <td>{{ subscription?.fiberType }} {{ subscription?.connectorType }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Domain' | translate }}</td>
                <td>{{ subscription?.domain }}</td>
              </tr>
              <tr *ngIf="subscription?.serviceTag">
                <td>{{ 'ServiceElement.ServiceTag' | translate }}</td>
                <td>{{ subscription?.serviceTag }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="productType === 'IP'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      (blur)="saveCustomerDescription()"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                <td>Internet (aka SURFinternet)</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
              </tr>
              <tr *ngIf="subscription?.availabilityZone">
                <td>Availability zone</td>
                <td>{{ subscription?.availabilityZone }}</td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr *ngIf="subscription?.ipRoutingType === 'BGP'">
                <td>{{ 'ServiceElement.ASNnummer' | translate }}</td>
                <td>{{ subscription?.ipss.asn }}</td>
              </tr>
              <tr *ngIf="subscription?.ipRoutingType === 'BGP'">
                <td>{{ 'ServiceElement.SURFASNnummer' | translate }}</td>
                <td>1103</td>
              </tr>
              <tr *ngIf="subscription?.ipss?.internetpinnen === true">
                <td>{{ 'ServiceElement.InternetpinnenPrefix' | translate }}</td>
                <td>
                  <ul>
                    <li *ngFor="let pin of subscription?.ip_prefixes">{{ pin.ip_prefix?.prefix }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFcertfilter' | translate }}</td>
                <td *ngIf="subscription">
                  <span
                    *ngIf="auth?.isCurrentOrganisation(subscription?.customerId) && auth?.hasRole(['Infraverantwoordelijke', 'surfcert-kernel', 'Beveiligingsverantwoordelijke']) && !subscriptionTerminated && !subscription.firewallEnabled; else readOnly"
                    class="table-properties__flex"
                    [ngClass]="{'table-properties__flex--blue': subscription?.ipss?.surfcertFilter === 'enabled', 'table-properties__flex--disabled': false }"
                  >
                    {{ subscription?.ipss?.surfcertFilter | enabled_or_value }}
                    <selfservice-toggle
                      [active]="subscription?.ipss?.surfcertFilter === 'enabled'"
                      [tooltipText]="'Selfservice.Tooltip.DDos' | translate"
                      (click)="openSelfserviceDialog('ddos')"
                    ></selfservice-toggle>
                  </span>
                  <ng-template #readOnly> {{ subscription?.ipss?.surfcertFilter | enabled_or_value }} </ng-template>
                </td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Domain' | translate }}</td>
                <td>{{ subscription?.domain }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.RouteringType' | translate }}</td>
                <td>{{ subscription?.ipRoutingType }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="productType === 'LightPath'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      (blur)="saveCustomerDescription()"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscription?.md_references.length > 0">
                <td>{{ 'ServiceElement.References' | translate }}</td>
                <td>
                  <div *ngFor="let reference of subscription?.md_references">{{ reference }}</div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                <td>EVPN - Point-to-point (aka SURFlichtpad)</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr
                *ngIf="subscription?._circuits && subscription?._circuits.length > 0 && subscription._circuits[0].speedPolicer !== null"
              >
                <td>{{ 'ServiceElement.SpeedPolicer' | translate }}</td>
                <td *ngIf="subscription">
                  <span
                    *ngIf="auth?.isSelfServiceEnabled && auth?.hasRole(['Infraverantwoordelijke']) && auth?.isCurrentOrganisation(subscription?.customerId) && !subscriptionTerminated; else readOnly"
                    class="table-properties__flex"
                    [ngClass]="{'table-properties__flex--blue': subscription._circuits[0].speedPolicer }"
                  >
                    {{ subscription._circuits[0].speedPolicer | enabled_or_value }}
                    <selfservice-toggle
                      [active]="subscription._circuits[0].speedPolicer"
                      (click)="openSelfserviceDialog('sp')"
                      [tooltipText]="'Selfservice.Tooltip.SpeedPolicer' | translate"
                    ></selfservice-toggle>
                  </span>
                  <ng-template #readOnly> {{ subscription._circuits[0].speedPolicer | enabled_or_value }} </ng-template>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Protection' | translate }}</td>
                <td>{{ subscription?.protection }}</td>
              </tr>
              <tr
                *ngIf="subscription?._circuits && subscription?._circuits.length > 0 && subscription._circuits[0].remotePortShutdown !== null"
              >
                <td>{{ 'ServiceElement.RemotePortShutdown' | translate }}</td>

                <td *ngIf="subscription">
                  <span
                    *ngIf="
                    auth?.hasRole(['Infraverantwoordelijke']) && !subscriptionTerminated && auth?.isSelfServiceEnabled
                    && allPortsAreNotAggregated
                    && allPortsAreUntagged; else readOnly"
                    class="table-properties__flex"
                    [ngClass]="{'table-properties__flex--blue': subscription._circuits[0].remotePortShutdown }"
                  >
                    {{ subscription._circuits[0].remotePortShutdown | enabled_or_value }}
                    <selfservice-toggle
                      [active]="subscription._circuits[0].remotePortShutdown"
                      (click)="openSelfserviceDialog('rps')"
                      [tooltipText]="'Selfservice.Tooltip.RemotePortShutdown' | translate"
                    ></selfservice-toggle>
                  </span>
                  <ng-template #readOnly>
                    {{ subscription._circuits[0].remotePortShutdown | enabled_or_value }}
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Domain' | translate }}</td>
                <td>{{ subscription?.domain }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="productType === 'L2VPN' || productType === 'L3VPN'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      (blur)="saveCustomerDescription()"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscription?.md_references.length > 0">
                <td>{{ 'ServiceElement.References' | translate }}</td>
                <td>
                  <div *ngFor="let reference of subscription?.md_references">{{ reference }}</div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                <td>
                  {{ (productType === 'L2VPN' ? "EVPN - Multipoint" : "L3VPN - Multipoint") }} (aka {{
                  subscription?.product?.name }})
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Protection' | translate }}</td>
                <td>{{ subscription?.protection }}</td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr
                *ngIf="subscription?._circuits && subscription?._circuits.length > 0 && subscription._circuits[0].speedPolicer !== null"
              >
                <td>{{ 'ServiceElement.SpeedPolicer' | translate }}</td>
                <td *ngIf="subscription">
                  <span
                    *ngIf="auth.isSelfServiceEnabled && auth?.hasRole(['Infraverantwoordelijke']) && auth?. isCurrentOrganisation(subscription?.customerId); else readOnly"
                    class="table-properties__flex"
                    [ngClass]="{'table-properties__flex--blue': subscription._circuits[0].speedPolicer }"
                  >
                    {{ subscription._circuits[0].speedPolicer | enabled_or_value }}
                    <selfservice-toggle
                      *ngIf="productType === 'L2VPN' && !subscription.firewallEnabled"
                      [active]="subscription._circuits[0].speedPolicer"
                      (click)="openSelfserviceDialog('sp')"
                    ></selfservice-toggle>
                  </span>
                  <ng-template #readOnly> {{ subscription._circuits[0].speedPolicer | enabled_or_value }} </ng-template>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Domain' | translate }}</td>
                <td>{{ subscription?.domain }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscription?.l3vpnss">
                <td>{{ 'ServiceElement.L3VPNLocalAS' | translate }}</td>
                <td>{{ subscription?.l3vpnss?.l3vpnAsn }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="productType === 'FW'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      (blur)="saveCustomerDescription()"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                <td>SURFfirewall</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.CustomerASN' | translate }}</td>
                <td>{{ subscription?.customerAsn }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.FWASN' | translate }}</td>
                <td>1103</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.AvailabilityZone' | translate }}</td>
                <td>{{ subscription?.availabilityZone }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Domain' | translate }}</td>
                <td>{{ subscription?.domain }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="productType === 'Wireless'">
          <div class="col-12 col-sm-7">
            <table class="table-properties table-properties--no-margin">
              <tr>
                <td>{{ 'ServiceElement.Owner' | translate }}</td>
                <td>{{ subscription?.organisation }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                <td>
                  <span class="alias" *ngIf="!isEditable">
                    {{ subscription?.hasPresentableName ? subscription.presentableName : '-' }}
                    <div class="alias__icon" (click)="startEditingCustomerDescription()">
                      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                    </div>
                  </span>
                  <div *ngIf="isEditable" class="alias__form">
                    <input
                      [(ngModel)]="temporaryName"
                      class="input"
                      type="text"
                      value="{{ subscription.presentableName }}"
                      autofocus
                    />
                    <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    </span>
                    <span
                      class="alias__form-button alias__form-button--cancel"
                      (click)="cancelEditingCustomerDescription()"
                    >
                      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                <td>{{ subscription?.description }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                <td>
                  <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                    >{{ subscription?.subscriptionId | shorten_guid }}
                    <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                      <div class="clipboard__icon">
                        <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                      </div> </span
                  ></span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-sm-5">
            <table class="table-properties table-properties--no-margin">
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.Status' | translate }}</td>
                <td>{{ subscription?.status }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Start' | translate }}</td>
                <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr *ngIf="subscriptionTerminated">
                <td>{{ 'ServiceElement.End' | translate }}</td>
                <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                <td>SURFwireless</td>
              </tr>
              <tr>
                <td>{{ 'ServiceElement.Supplier' | translate }}</td>
                <td>{{ subscription?.supplier }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="subscriptionTerminated" class="hide-everything-after-this-div"></div>
  <div class="divider" *ngIf="!hasTabs && !subscriptionTerminated">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <page-tab *ngIf="hasTabs" [productType]="productType" (activeTabEmitter)="changeActiveTab($event)"> </page-tab>
  <div *ngIf="activeTab === 'graphic-view'">
    <networkgraph [subscriptionId]="subscription?.subscriptionId" #chart></networkgraph>
  </div>
  <div *ngIf="activeTab === 'system-stats'">
    <div *ngIf="productType === 'FW'">
      <system-stats [subscription]="subscription"></system-stats>
      <linechart id="chart" #chart [subscription]="subscription"></linechart>
    </div>
  </div>
  <div *ngIf="activeTab === 'config'">
    <div *ngIf="productType === 'Port'" class="section">
      <subscription-detail-container
        [productType]="productType"
        [service]="subscription"
      ></subscription-detail-container>
    </div>
    <div class="section" *ngIf="productType === 'FW'">
      <uplink-detail-container [subscription]="subscription" [bus]="bus"> </uplink-detail-container>
    </div>
    <div *ngIf="productType === 'Wireless'" class="section">
      <div class="row">
        <div class="col-12 col-lg-7">
          <h3 class="title-with-icon">
            <svg-icon src="assets/images/icons/accesspoint.svg" class="black-medium"></svg-icon>
            <span>Type access points</span>
          </h3>
          <access-points [subscriptionId]="subscription?.subscriptionId"></access-points>
        </div>
        <div class="col-12 col-lg-5">
          <h3 class="title-with-icon">
            <svg-icon src="assets/images/icons/usergroup.svg" class="black-medium"></svg-icon>
            <span>{{ 'Wireless.Stats.MaxUsers' | translate }}</span>
          </h3>
          <user-stats [wirelessHealthData]="wirelessHealthData"></user-stats>
        </div>
      </div>
    </div>
    <div *ngIf="productType === 'Wireless'" class="section">
      <div class="row">
        <div class="col-12">
          <h3 class="title-with-icon">
            <svg-icon src="assets/images/icons/performance.svg" class="black-medium"></svg-icon>
            <span>Key performance indicators</span>
          </h3>
          <ng-container [ngSwitch]="subscription?.sensorPresent">
            <loader class="card" *ngSwitchCase="undefined" [paddingSize]="'small'"></loader>
            <ng-container *ngSwitchCase="true">
              <kpi-table
                *ngIf="wirelessHealthData !== undefined"
                [vendor]="subscription.supplier?.toLowerCase()"
                [wirelessHealthData]="wirelessHealthData"
              ></kpi-table>
              <loader class="card" *ngIf="wirelessHealthData === undefined" [paddingSize]="'small'"></loader>
            </ng-container>
            <div class="card" *ngSwitchCase="false">No sensor present</div>
            <div class="card" *ngSwitchDefault>Unknown sensor state</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="section" *ngIf="['IP', 'L2VPN', 'L3VPN', 'FW'].includes(productType)">
      <tabbed-detail-container
        [service]="subscription"
        [bus]="bus"
        [canModify]="productType !== 'FW'"
        [selfserviceState]="selfserviceState"
      >
      </tabbed-detail-container>
    </div>
    <div class="section">
      <div *ngIf="productType === 'LightPath'">
        <connection
          *ngFor="let circuit of subscription?._circuits; index as i"
          [port]="circuit.endpoints"
          [connectionNumber]="i + 1"
          [service]="subscription"
          [bus]="bus"
        ></connection>
      </div>
    </div>
    <linechart id="chart" #chart [subscription]="subscription"></linechart>
  </div>
</div>
<div class="container">
  <footer></footer>
</div>

<ng-template #problemModal>
  <div class="dialog" style="min-height: 0px !important">
    <div class="dialog__header">
      <h3 class="dialog__title">{{ 'Services.Fulfilment.ReportProblem' | translate }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-12">
          <label for="" class="form-label">{{ 'ServiceElement.SURFnetDescription' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.description }}" disabled />
          </div>
          <label for="" class="form-label">UUID</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.subscriptionId }}" disabled />
          </div>
          <label for="" class="form-label">{{ 'Global.Email' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="{{ 'Global.Email' | translate }}" />
          </div>
          <label for="" class="form-label">{{ 'Global.Message' | translate }}</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="{{ 'Global.Message' | translate }}"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog__footer dialog__footer--end">
      <div class="button__flex">
        <span class="button button--secondary">{{ 'Global.Action.Cancel' | translate }}</span>
        <span class="button">{{ 'Global.Action.Confirm' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
