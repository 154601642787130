import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from "@angular/core";
import { SelfServiceCommand, SelfServicePayloads } from "../../../helpers/self-service/self-service-command";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyResponse, ModifyErrorDetail } from "../../../helpers/self-service/models/types";
import { ModifyLightPath, ModifyRedundantLightPath } from "../../../helpers/self-service/modify_lightpath";
import { ModifyL2VPNSpeedPolicer } from "../../../helpers/self-service/modify_l2vpn";
import { transformError } from "../../../helpers/self-service/transform-error";
import {
  L2vpnSubscriptionDetails,
  LightPathRedundantSubscriptionDetails,
  LightPathSubscriptionDetails,
} from "../../../pages/subscription-detail-replicated/types";

@Component({
  selector: "selfservice-sp",
  templateUrl: "selfservice-sp.html",
})
export class SelfserviceSpComponent implements OnChanges {
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription:
    | L2vpnSubscriptionDetails
    | LightPathSubscriptionDetails
    | LightPathRedundantSubscriptionDetails;
  @Input() bus: EventEmitter<any>;

  public filterStatus: boolean;
  public processId: string;
  public hasErrors = false;
  public errors: ModifyErrorDetail[] = [];
  public buttonLoading = false;

  constructor(public api: ApiHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      // @ts-ignore FIXME Remove ts-ignore and test. Also remove _circuits
      const saps = this.subscription._circuits || this.subscription.circuits;
      this.filterStatus = saps[0].speedPolicer;
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  submit() {
    this.buttonLoading = true;

    let commandKey: SelfServiceCommandKeys;
    let modify: SelfServicePayloads;
    const product = this.subscription.product;
    // @ts-ignore FIXME Remove ts-ignore and test
    const productType = product.type || product.productType;
    if (productType === "L2VPN") {
      modify = new ModifyL2VPNSpeedPolicer();
      commandKey = SelfServiceCommandKeys.ModifyL2VpnSpeedPolicer;
    } else {
      modify = product.tag === "LP" ? new ModifyLightPath() : new ModifyRedundantLightPath();
      commandKey =
        product.tag === "LP" ? SelfServiceCommandKeys.ModifyLightpath : SelfServiceCommandKeys.ModifyRedundantLightpath;
    }

    const command = new SelfServiceCommand(this.subscription.subscriptionId, commandKey);
    modify.subscription = this.subscription;
    modify.speedPolicer = !this.filterStatus;
    command.payload = modify;

    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .catch((err) => {
        this.errors = [{ loc: [], type: "", msg: "Self service failed" }];
        this.buttonLoading = false;
        console.error(err);
        this.errors = transformError(err);
        this.hasErrors = true;
      })
      .then((response) => {
        if (response) {
          this.processId = (response as ModifyResponse).pid;
          this.bus.emit({ processId: this.processId, action: commandKey });
        }
      });
  }
}
