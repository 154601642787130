import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SubscriptionsPage as SubscriptionsPageReplicated } from "../pages/subscriptions-replicated/subscriptions";
import { SubscriptionDetailPage } from "../pages/subscription-detail/subscription-detail";
import { SubscriptionDetailPage as SubscriptionDetailPageReplicated } from "../pages/subscription-detail-replicated/subscription-detail";
import { SupportPage } from "../pages/support/support";
import { MessagecenterPage } from "../pages/messagecenter/messagecenter";
import { AboutPage } from "../pages/about/about";
import { DisclaimerPage } from "../pages/disclaimer/disclaimer";
import { ErrorPage } from "../pages/error/error";
import { NotfoundPage } from "../pages/notfound/notfound";
import { ForbiddenPage } from "../pages/forbidden/forbidden";
import { MalfunctionPage } from "../pages/malfunction/malfunction";
import { AvailabilityExportPage } from "../pages/availability-export/availability-export";
import { NotificationSettingsPage } from "../pages/notification-settings/notification-settings";
import { CertPage } from "../pages/cert/cert";
import { AuthGuard } from "./auth/guard";
import { ENV } from "./app.runtime";
import { PortSubscriptionComponent } from "../pages/subscription-detail-replicated/components/port-subscription/port-subscription";
import { MscSubscriptionComponent } from "../pages/subscription-detail-replicated/components/msc-subscription/msc-subscription";
import { AggspSubscriptionComponent } from "../pages/subscription-detail-replicated/components/aggsp-subscription/aggsp-subscription";
import { LightpathSubscriptionComponent } from "../pages/subscription-detail-replicated/components/lightpath-subscription/lightpath-subscription";
import { LightpathRedundantSubscriptionComponent } from "../pages/subscription-detail-replicated/components/lightpath-subscription/lightpath-redundant-subscription";
import { DashboardPageReplicated } from "../pages/dashboard-replicated/dashboard";
import { IPPrefixesPageReplicated } from "../pages/ip-prefixes-replicated/ip-prefixes";
import { WirelessSubscriptionComponent } from "../pages/subscription-detail-replicated/components/wireless-subscription/wireless-subscription";
import { FirewallSubscriptionComponent } from "../pages/subscription-detail-replicated/components/firewall-subscription/firewall-subscription";
import { L2vpnSubscriptionComponent } from "../pages/subscription-detail-replicated/components/l2vpn-subscription/l2vpn-subscription";
import { L3vpnSubscriptionComponent } from "../pages/subscription-detail-replicated/components/l3vpn-subscription/l3vpn-subscription";
import { IpBgpSubscriptionComponent } from "../pages/subscription-detail-replicated/components/ip-subscription/ip-bgp-subscription";
import { IpStaticSubscriptionComponent } from "../pages/subscription-detail-replicated/components/ip-subscription/ip-static-subscription";

const replicationRoutes = [
  { path: "subscription/L2VPN/:subscriptionId", component: L2vpnSubscriptionComponent, canActivate: [AuthGuard] },
];

const unreplicatedRoutes = [
  { path: "subscription/L2VPN/:subscriptionId", component: SubscriptionDetailPage, canActivate: [AuthGuard] },
];

export const appRoutes: Routes = [
  { path: "subscription/FW/:subscriptionId", component: FirewallSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/Wireless/:subscriptionId", component: WirelessSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "ip-prefixes", component: IPPrefixesPageReplicated, canActivate: [AuthGuard] },
  { path: "diensten/:productType", component: SubscriptionsPageReplicated, canActivate: [AuthGuard] },
  { path: "subscription/L3VPN/:subscriptionId", component: L3vpnSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/IP/IPBGP/:subscriptionId", component: IpBgpSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/IP/IPS/:subscriptionId", component: IpStaticSubscriptionComponent, canActivate: [AuthGuard] },
  {
    path: "subscription/LightPath/LP/:subscriptionId",
    component: LightpathSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "subscription/LightPath/LR/:subscriptionId",
    component: LightpathRedundantSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  { path: "subscription/Port/SP/:subscriptionId", component: PortSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/Port/MSC/:subscriptionId", component: MscSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/Port/AGGSP/:subscriptionId", component: AggspSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/Port/SPNL/:subscriptionId", component: PortSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/Port/MSCNL/:subscriptionId", component: MscSubscriptionComponent, canActivate: [AuthGuard] },
  {
    path: "subscription/Port/AGGSPNL/:subscriptionId",
    component: AggspSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "subscription/:subscriptionId",
    component: ENV.REPLICATION_ENABLED ? SubscriptionDetailPageReplicated : SubscriptionDetailPage,
  },
  ...(ENV.REPLICATION_ENABLED ? replicationRoutes : unreplicatedRoutes),
  { path: "support", component: SupportPage },
  { path: "berichten", component: MessagecenterPage },
  { path: "berichten/:id", component: MessagecenterPage },
  { path: "storing-en-onderhoud", component: MalfunctionPage },
  {
    path: "storing-en-onderhoud/:productType/:subscriptionId",
    component: MalfunctionPage,
    canActivate: [AuthGuard],
  },
  {
    path: "storing-en-onderhoud/:productType",
    component: MalfunctionPage,
    canActivate: [AuthGuard],
  },
  { path: "over-surf", component: AboutPage },
  { path: "disclaimer", component: DisclaimerPage },
  { path: "error", component: ErrorPage },
  { path: "notfound", component: NotfoundPage },
  { path: "forbidden", component: ForbiddenPage },
  { path: "inloggen.php", redirectTo: "/" },
  { path: "availability-export/:id", component: AvailabilityExportPage },
  { path: "notification-settings", component: NotificationSettingsPage },
  { path: "cert", component: CertPage, canActivate: [AuthGuard] },
  { path: "", component: DashboardPageReplicated },
  { path: "domains", loadChildren: () => import("./domains/domains.module").then((m) => m.DomainsModule) },
  { path: "**", redirectTo: "notfound" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
