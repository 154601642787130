import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
import { AuthService } from "../../services/AuthService";
import { Subscription as RxSubscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { collectTags } from "../../helpers/subscription/collect-tags";
import { MessageService } from "../../services/messageservice";
import { Instance } from "../../components/models/instance";
import { ApiHelper } from "../../helpers/apihelper";
import { getFilteredItems, IFilter } from "./helpers";

import { Apollo, ApolloBase } from "apollo-angular";
import { LIST_QUERIES } from "../../graphql/replication/subscription-list";
import { StorageHelper } from "../../helpers/storage/storagehelper";
import { LoadingState } from "../../services/loading-state";
import { ENV } from "../../app/app.runtime";

@Component({
  selector: "page-subscriptions-replicated",
  templateUrl: "subscriptions.html",
})
export class SubscriptionsPage implements OnInit, OnDestroy {
  public subscriptions: Instance[];
  public servicesSearch: string;
  public collectedTags: string[] = [];
  public collectedLocations: string[] = [];
  public linkedOrganisations: string[] = [];

  public filteredSubscriptions: Instance[];
  public activeFilters: string[] = [];
  public activeTags: string[] = [];
  public activeLocations: string[] = [];
  public filterActive = false;

  public productType = "";
  private subEnableFilter: RxSubscription;
  private subDisableFilter: RxSubscription;
  private replicatedData: ApolloBase;

  constructor(
    private matomo: MatomoTracker,
    private auth: AuthService,
    public api: ApiHelper,
    private route: ActivatedRoute,
    private msgsrv: MessageService,
    public loadingState: LoadingState,
    private apolloProvider: Apollo,
  ) {
    this.replicatedData = this.apolloProvider.use("replicatedData");
    matomo.setDocumentTitle("Services");
    this.productType = this.extractProductType();

    this.subEnableFilter = this.msgsrv.enableFilter$.subscribe((filter: IFilter) => {
      switch (filter.type) {
        case "state":
          // Add to active filters
          this.activeFilters.push(filter.value);
          break;
        case "tag":
          // Add to active tags
          this.activeTags.push(filter.value);
          break;
        case "location":
          this.activeLocations.push(filter.value);
          break;
        default:
          break;
      }

      this.filteredSubscriptions = getFilteredItems(
        this.subscriptions,
        this.activeFilters,
        this.activeTags,
        this.activeLocations,
        this.servicesSearch,
      );
      this.filterActive = true;
    });

    this.subDisableFilter = msgsrv.disableFilter$.subscribe((filter) => {
      switch (filter.type) {
        case "state":
          // Remove from active filters
          const filterIndex = this.activeFilters.indexOf(filter.value);
          if (filterIndex > -1) {
            this.activeFilters.splice(filterIndex, 1);
          }
          break;
        case "tag":
          // Remove from active tags
          const tagIndex = this.activeTags.indexOf(filter.value);
          if (tagIndex > -1) {
            this.activeTags.splice(tagIndex, 1);
          }
          break;
        case "location":
          const locIndex = this.activeLocations.indexOf(filter.value);
          if (locIndex > -1) {
            this.activeLocations.splice(locIndex, 1);
          }
          break;
        default:
          break;
      }

      // No filters active anymore
      if (this.activeTags.length === 0 && this.activeFilters.length === 0 && this.activeLocations.length === 0) {
        this.filterActive = false;
        this.filteredSubscriptions = [];
        return;
      }

      // Apply filters that are still active
      this.filteredSubscriptions = getFilteredItems(
        this.subscriptions,
        this.activeFilters,
        this.activeTags,
        this.activeLocations,
        this.servicesSearch,
      );
    });
  }

  ngOnInit() {
    if (!ENV.REPLICATION_ENABLED) {
      // tslint:disable-next-line:no-console
      console.warn(`Accessing replicated ${this.productType} list page`);
    }

    this.matomo.trackPageView();
    this.auth.userLoaded.subscribe((d) => {
      this.gqlFetch();
    });
    if (!this.auth.state.loading) {
      this.gqlFetch();
    }
  }

  extractProductType(): string {
    let productType = this.route.snapshot.paramMap.get("productType");
    if (!productType) {
      const pathDirs = (this.route.snapshot.routeConfig.path ?? "").split("/");
      if (pathDirs.length > 0) {
        productType = pathDirs[pathDirs.length - 1];
      }
    }

    if (!productType) {
      throw Error("Unable to load subscription list page: product type unknown");
    }

    return productType;
  }

  async gqlFetch() {
    this.subscriptions = [];
    const query = LIST_QUERIES[this.productType];
    this.replicatedData
      .query({
        query,
        variables: { customer: StorageHelper.currentUser },
      })
      .subscribe(({ data }) => {
        this.subscriptions = data?.subscriptions.page.map((sub) => Object.assign(new Instance(), sub));
        this.subscriptions.sort((a, b) => a.subscriptionId.localeCompare(b.subscriptionId));
        // this.subscriptions.forEach((s) => this.subscriptionsMeta[s.subscriptionId] = s.)
        // this.subscriptionsMeta[]
        this.collectedTags = collectTags(this.subscriptions);
      });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subEnableFilter.unsubscribe();
    this.subDisableFilter.unsubscribe();
  }

  searchValueChange(event: any) {
    this.filterActive = true;

    // Apply filters that active
    this.filteredSubscriptions = getFilteredItems(
      this.subscriptions,
      this.activeFilters,
      this.activeTags,
      this.activeLocations,
      this.servicesSearch,
    );
  }

  trackByFn(index: number, item: any) {
    return item.subscriptionId;
  }
}
