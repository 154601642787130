import { Instance } from "../../components/models/instance";
import { ESI, ServicePort } from "./models/types";

export class ModifyL2VPN {
  protected esis: ESI[];
  protected speed_policer?: boolean;

  set speedPolicer(val: boolean) {
    this.speed_policer = val;
  }

  set subscription(sub: Instance) {
    this.esis = sub.esis.map((esi) => {
      if (esi.endpoints) {
        const service_ports: ServicePort[] = esi.endpoints.map(
          (endpoint) =>
            ({
              subscription_id: endpoint.port.subscriptionId,
              vlan: endpoint.vlanrange,
            }) as ServicePort,
        );
        return service_ports;
      }
      return [];
    });

    // filter empty items
    this.esis = this.esis.filter((el) => el.length > 0);

    const circuits = sub.circuits || sub._circuits;
    this.speed_policer = circuits[0].speedPolicer;
  }

  addEsi(esi: ESI) {
    this.esis.push(esi);
  }

  setEsis(esis: ESI[]) {
    this.esis = esis;
  }
}

export class ModifyL2VPNSpeedPolicer extends ModifyL2VPN {
  get payload() {
    return {
      speed_policer: this.speed_policer,
    };
  }
}

export class ModifyL2VPNVlan extends ModifyL2VPN {
  get payload() {
    return {
      esis: this.esis,
    };
  }
}

export class ModifyL2VPNRemovePort extends ModifyL2VPN {
  get payload() {
    return {
      esis: this.esis.map((esi) =>
        esi.map((port) => ({
          subscription_id: port.subscription_id,
          vlan: port.vlan,
        })),
      ),
    };
  }
}

export class ModifyL2VPNAddPort extends ModifyL2VPN {
  get payload() {
    return {
      esis: this.esis,
    };
  }
}
