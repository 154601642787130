<div class="card">
  <div class="service">
    <div class="service__left service__left--no-padding">
      <svg-icon src="assets/images/icons/status-{{ healthState | status_to_icon }}.svg"></svg-icon>
      <div class="service__header-caption">
        <div
          *ngIf="showVisual"
          class="service__title-wrapper service__title-wrapper--space-between"
          [ngClass]="{'card--clickable': port?.portMode !== 'Link Member'}"
          [routerLink]="port?.portMode !== 'Link Member' ? ['/subscription/Port/'+port?.subscriptionId] : []"
        >
          <h3 class="service__title">{{ portPresentableName }}</h3>
          <svg-icon class="service__title-arrow" src="assets/images/icons/arrow-small-right.svg"></svg-icon>
        </div>
        <div *ngIf="!showVisual" class="service__title-wrapper service__title-wrapper--space-between">
          <h3 class="service__title">{{ portPresentableName }}</h3>
          <svg-icon class="service__title-arrow" src="assets/images/icons/arrow-small-right.svg"></svg-icon>
        </div>
        <div class="tags">
          <div *ngFor="let tag of port?.tags" class="tag">{{ tag }}</div>
        </div>
      </div>
    </div>
    <div class="service__flex-table" *ngIf="port?.portMode !== 'Link Member'">
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'ServiceElement.Owner' | translate }}</div>
          <div class="service__flex-table-subtitle">{{ portOwner }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'Services.Fulfilment.Lightpath.Location' | translate }}</div>
          <div class="service__flex-table-subtitle">
            {{ port?.address?.city }} ({{ port?.address?.street }} {{ port?.address?.number }})
          </div>
        </div>
      </div>
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'ServiceElement.PortID' | translate }}</div>
          <div class="service__flex-table-subtitle">{{ port?.subscriptionId | shorten_guid }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'Subscription.VLANID' | translate }}</div>
          <div class="service__flex-table-subtitle">
            <span class="port__title" *ngIf="service?.vlanrange && service?.vlanrange !== 0"
              >{{ service?.vlanrange }}</span
            >
            <span class="port__title" *ngIf="!service?.vlanrange || service?.vlanrange === 0"
              >{{ 'Subscription.UntaggedPort' | translate }}</span
            >
          </div>
        </div>
      </div>
      <div *ngIf="port?.serviceTag" class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">S-TAG</div>
          <div class="service__flex-table-subtitle">{{ port?.serviceTag }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title"></div>
          <div class="service__flex-table-subtitle"></div>
        </div>
      </div>
    </div>
    <div class="service__flex-table" *ngIf="port?.portMode === 'Link Member'">
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'ServiceElement.PortSpeed' | translate }}</div>
          <div class="service__flex-table-subtitle">{{ port?.portSpeed/ 1000 }} Gb</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'ServiceElement.PatchPosition' | translate }}</div>
          <div class="service__flex-table-subtitle">{{ port?.patchPosition }}</div>
        </div>
      </div>
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ 'ServiceElement.InterfaceType' | translate }}</div>
          <div class="service__flex-table-subtitle">{{ service?.ieeeInterfaceType }}</div>
        </div>
      </div>
    </div>
    <port-visualiser *ngIf="showVisual && service" [subscriptionId]="service?.port?.subscriptionId"></port-visualiser>
    <div *ngIf="!showVisual" class="service__unavailable-bar">
      <span class="service__unavailable-bar-side">
        <svg-icon class="service__unavailable-bar-icon" src="assets/images/icons/lock.svg"></svg-icon>
      </span>
      <span class="service__unavailable-bar-text">
        <strong>{{ 'Global.NoAccess' | translate }}</strong> {{ 'Global.PleaseContact' | translate }} {{
        service.organisation }}
      </span>
      <span class="service__unavailable-bar-side"></span>
    </div>
  </div>
</div>
