<div class="shortcut-menu card card--no-padding">
  <div class="shortcut-menu__header">
    <h3>{{ 'ShortcutMenu.Title' | translate }}</h3>
  </div>
  <div *ngIf="subscription" class="shortcut-menu__body">
    <button
      *ngIf="subscription?.product.productType !== 'Wireless'"
      (click)="redirectToMalfunction()"
      class="shortcut-menu__button"
    >
      <svg-icon src="assets/images/icons/notification.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.Malfunction' | translate }}</span>
      <div [ngClass]="{'red': messages.length > 0}" class="shortcut-menu__badge">{{ messages.length }}</div>
    </button>
    <button
      *ngIf="subscription?.product.productType === 'LightPath' || subscription?.product.productType === 'IP'"
      (click)="showSlsDialog()"
      class="shortcut-menu__button"
    >
      <svg-icon src="assets/images/icons/tick-circle.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.Availability' | translate }}</span>
    </button>
    <button
      *ngIf="subscription?.product.productType !== 'Wireless' && subscription?.product.productType !== 'Port' && subscription?.product.productType !== 'FW' && subscription?.product.productType !== 'L3VPN' && auth?.hasRole(['Infraverantwoordelijke', 'surfcert-kernel', 'Beveiligingsverantwoordelijke'])"
      class="shortcut-menu__button"
      (click)="startSelfService()"
    >
      <svg-icon *ngIf="!isAuthenticatedForSelfService" src="assets/images/icons/cog.svg"></svg-icon>
      <svg-icon
        *ngIf="isAuthenticatedForSelfService"
        src="assets/images/icons/cog-check.svg"
        class="shortcut-menu__button-icon--green"
      ></svg-icon>
      <span *ngIf="!isAuthenticatedForSelfService">{{ 'ShortcutMenu.SelfService' | translate }}</span>
      <span *ngIf="isAuthenticatedForSelfService" class="shortcut-menu__started"
        >{{ 'ShortcutMenu.SelfServiceStarted' | translate }}</span
      >
      <div *ngIf="isAuthenticatedForSelfService" class="shortcut-menu__green-text">
        {{ 'ShortcutMenu.Till' | translate }}
        <!-- 19:40 -->{{ selfServiceTokenExpires | twentyfourhourtime }}
      </div>
    </button>
    <button
      *ngIf="subscription?.product.productType !== 'Port' && subscription?.product.productType !== 'FW' && subscription?.product.productType !== 'L3VPN' && !auth?.hasRole(['Infraverantwoordelijke', 'surfcert-kernel', 'Beveiligingsverantwoordelijke'])"
      class="shortcut-menu__button"
      (click)="showWrongRoleMessage()"
    >
      <svg-icon src="assets/images/icons/cog.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.SelfService' | translate }}</span>
    </button>
    <a
      *ngIf="subscription?.product.productType === 'LightPath' || subscription?.product.productType === 'IP'"
      class="shortcut-menu__button"
      routerLink="/availability-export/{{ subscription?.subscriptionId }}"
      target="_blank"
    >
      <svg-icon src="assets/images/icons/export.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.Export' | translate }}</span>
    </a>
    <button
      *ngIf="subscription?.product.productType !== 'Wireless'"
      class="shortcut-menu__button"
      (click)="openSendConfigDialog()"
    >
      <svg-icon src="assets/images/icons/send.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.SendConfig' | translate }}</span>
    </button>

    <button
      *ngIf="subscription?.product.productType === 'Wireless'"
      class="shortcut-menu__button"
      (click)="openTicketsDialog($event)"
    >
      <svg-icon src="assets/images/icons/notification.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.Tickets' | translate }}</span>
      <div [ngClass]="{'red': messages.length > 0}" class="shortcut-menu__badge">{{ messages.length }}</div>
    </button>

    <a
      *ngIf="subscription?.product.productType === 'Wireless' && subscription?.supplier === 'Aerohive'"
      class="shortcut-menu__button"
      href="https://extremecloudiq.com"
      target="_blank"
    >
      <svg-icon src="assets/images/icons/out.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.Hive' | translate }} </span>
    </a>
    <a
      *ngIf="subscription?.product.productType === 'FW'"
      class="shortcut-menu__button"
      href="https://fn-beheer.nfv.surf.net/p/login/"
      target="_blank"
    >
      <svg-icon src="assets/images/icons/out.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.FortiManager' | translate }} </span>
    </a>
    <a
      *ngIf="subscription?.product.productType === 'FW'"
      class="shortcut-menu__button"
      href="https://fn-log.nfv.surf.net/p/login/"
      target="_blank"
    >
      <svg-icon src="assets/images/icons/out.svg"></svg-icon>
      <span>{{ 'ShortcutMenu.FortiAnalyzer' | translate }} </span>
    </a>
  </div>
  <div *ngIf="!subscription">
    <loader></loader>
  </div>
</div>
