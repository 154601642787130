<navigation [activeItem]="1" [requestsBubble]="3"></navigation>
<div class="container">
  <div class="row">
    <div class="col-4 col-filter">
      <div class="filter">
        <section class="section">
          <div class="input__wrapper">
            <input
              type="search"
              [(ngModel)]="servicesSearch"
              value=""
              class="input input--search"
              name=""
              placeholder="{{ 'Form.Placeholder.SearchTerm' | translate }}"
              (keyup)="searchValueChange($event)"
            />
          </div>
        </section>
        <section *ngIf="productType !== 'Wireless'" class="section">
          <h3>{{ 'Services.Filter.FilterByStatus' | translate }}</h3>
          <filter-bar
            filterTitle="{{ 'Services.Status.Ok' | translate }}"
            filterIcon="status-ok"
            filterType="state"
            filterValue="ok"
            filterClassActive="active"
            filterDefaultActive="false"
          ></filter-bar>
          <filter-bar
            filterTitle="{{ 'Services.Status.NoTraffic' | translate }}"
            filterIcon="status-no-traffic"
            filterType="state"
            filterValue="notraffic"
            filterClassActive="active"
            filterDefaultActive="false"
          >
          </filter-bar>
          <filter-bar
            filterTitle="{{ 'Services.Status.Warning' | translate }}"
            filterIcon="status-warning"
            filterType="state"
            filterValue="malfunction"
            filterClassActive="active"
            filterDefaultActive="false"
          >
          </filter-bar>
          <filter-bar
            filterTitle="{{ 'Services.Status.Pending' | translate }}"
            filterIcon="status-pending"
            filterType="state"
            filterValue="pending"
            filterClassActive="active"
            filterDefaultActive="false"
          >
          </filter-bar>
          <filter-bar
            filterTitle="{{ 'Services.Status.Unknown' | translate }}"
            filterIcon="status-unknown"
            filterType="state"
            filterValue="unknown"
            filterClassActive="active"
            filterDefaultActive="false"
          >
          </filter-bar>
        </section>

        <section class="section">
          <h3>{{ 'Services.Tags' | translate }}</h3>
          <div class="checktag__flex" *ngIf="collectedTags.length > 0">
            <checktag *ngFor="let tag of collectedTags" checktagTitle="{{tag}}"></checktag>
          </div>
        </section>
        <section *ngIf="productType !== 'Wireless'" class="section">
          <h3>{{ 'Services.Filter.Endpoint' | translate }}</h3>
          <completer-location [locations]="collectedLocations"></completer-location>
        </section>
      </div>
    </div>
    <div class="col-12 col-md-8 col-content">
      <section class="section" *ngIf="filterActive === true">
        <div class="row" *ngFor="let sub of filteredSubscriptions; trackBy: trackByFn">
          <div class="col-12">
            <service-element-simple-replicated
              [subscription]="sub"
              [productType]="productType"
            ></service-element-simple-replicated>
          </div>
        </div>
      </section>
      <section class="section" *ngIf="filterActive === false">
        <loader
          *ngIf="!subscriptions || loadingState.remaining > 0"
          [detailed]="true"
          [small]="true"
          [text]="loadingState.asText"
        ></loader>

        <div class="row" *ngFor="let sub of subscriptions; trackBy: trackByFn">
          <div class="col-12">
            <service-element-simple-replicated
              [subscription]="sub"
              [productType]="productType"
            ></service-element-simple-replicated>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<div class="container">
  <footer></footer>
</div>
