import { Component, Input, Output, Inject, EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "dialog-selfservice-replicated",
  templateUrl: "dialog-selfservice.html",
  styleUrls: ["dialog-selfservice.scss"],
})
export class DialogSelfserviceReplicated {
  @Input() loader = false;
  @Input() loadingData = false;
  @Input() success = false;
  @Input() computedTitle = "";
  @Input() processId: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<string | Event> = new EventEmitter();
  public communication: EventEmitter<any> = new EventEmitter();
  public subscription;
  public circuit;
  public instance;
  public sapIndex;
  public runningAction: string;
  currentStep: number;
  selectedPrefix: string;
  type: any;

  constructor(
    public dialogRef: MatDialogRef<DialogSelfserviceReplicated>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      subscription: any;
      instance: any;
      sapIndex: any;
      circuit: any;
      titleSuffix: any;
      selectedPrefix: any;
      currentStep: any;
      mitigationState: "enabled" | "disabled";
    },
    private translate: TranslateService,
    private router: Router,
    private api: ApiHelper,
    public auth: AuthService,
  ) {
    dialogRef.disableClose = true;
    this.subscription = data.subscription;
    this.instance = data.instance;
    this.sapIndex = data.sapIndex;
    this.circuit = data.circuit;

    this.communication.subscribe((event: any) => {
      if (event.processId) {
        this.processId = event.processId;
        this.runningAction = event.action || "unknown";
        this.loader = true;
      } else if (event.status) {
        switch (event.status) {
          case "completed":
            this.refresh("refresh");
            break;
          case "failed":
            alert("err");
            break;
          case "notify_support":
            this.notifySupport();
            break;
          case "error":
            this.notifySupport();
            break;
        }
      }
    });
  }

  get title() {
    const title = this.translate.get("Selfservice.Title." + this.data.type).subscribe((res: string) => {
      this.computedTitle = res;
    });
    return this.computedTitle;
  }

  get titleSuffix() {
    return this.data.titleSuffix ? this.data.titleSuffix : null;
  }

  closeDialog(event: string | Event): void {
    this.dialogRef.close();
    this.close.emit(event); // notify subscription-detail
  }

  refresh(event: string): void {
    this.success = true;
    this.close.emit(event); // notify subscription-detail
  }

  notifySupport(): void {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    // note: not the type from /components/models.
    // we don't need any verification / errorchecking here.
    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.auth.state.currentUser.displayName,
          email: this.auth.state.currentUser.email,
          phone: "06123", // placeholder, since we don't know the value.
        },
      ],
      serviceRequest: "problem",
      text: this.translate.instant("Selfservice.FulfilmentProblem.Message", {
        subscription_name: this.subscription.description,
        action: this.runningAction,
        process_id: this.processId,
      }),
      subscriptionId: this.subscription.subscriptionId,
      product: this.subscription.product.productType,
    };

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.closeDialog("refresh");
      })
      .catch((err) => this.closeDialog("refresh"));
  }
}
