import { Component, EventEmitter, Output, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MessagePart, ModifyIpPrefixes } from "../../../helpers/self-service/modify_ip_prefixes";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { ApiHelper } from "../../../helpers/apihelper";
import { IPPrefixSubscription, ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { HttpErrorResponse } from "@angular/common/http";
import { Instance } from "../../../components/models/instance";
import { transformError } from "../../../helpers/self-service/transform-error";
import { TranslateService } from "@ngx-translate/core";
import { InternetInstance } from "../../../components/models/internetinstance";
import { Apollo } from "apollo-angular";
import { SUBSCRIPTIONS_OF_TYPE_QUERY } from "../../../graphql/domain/subscription";

type SubscriptionInstanceId = string;
type PrefixSubscriptionId = string;
interface ServicePayloadItem {
  [x: SubscriptionInstanceId]: PrefixSubscriptionId[];
}

@Component({
  selector: "selfservice-ip-prefixes",
  templateUrl: "selfservice-ip-prefixes.html",
  styleUrls: ["selfservice-ip-prefixes.scss"],
})
export class SelfserviceIPPrefixesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentStep = 1;
  @Input() subscription: InternetInstance;
  @Input() selectedPrefix;
  @Input() sapIndex = 0;
  @Input() bus: EventEmitter<any>;
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  public errorMessage = null;
  public buttonLoading = false;
  public errors: ModifyErrorDetail[] = [];
  public hasErrors = false;
  public subscriptionCopy;

  // keeping track of added and/or removed prefixes per sap
  public addedPrefixes: Map<PrefixSubscriptionId, Array<SubscriptionInstanceId>> = new Map();
  public removedPrefixes: Map<PrefixSubscriptionId, Array<SubscriptionInstanceId>> = new Map();

  searchTerm: string;
  term: string;

  availablePrefixMapping = [];
  fetchedPrefixes = [];

  expandIndexes = [];
  selectedItems = [];
  originalSelectedItems = {};

  private processId: string;

  constructor(
    public subscriptionService: SubscriptionService,
    public api: ApiHelper,
    private apollo: Apollo,
  ) {}

  get didRemovePrefixes(): boolean {
    const keys = Object.keys(this.originalSelectedItems);
    for (let i = 0; i < keys.length; i++) {
      if (this.removedItems(i).length) {
        return true;
      }
    }
    return false;
  }

  get currentSapSubscription(): string {
    return this.subscriptionCopy._saps[this.sapIndex].subscriptionInstanceId;
  }

  get availableItems(): any {
    return this.availablePrefixMapping[this.sapIndex];
  }

  get servicePayload(): { added: ServicePayloadItem; removed: ServicePayloadItem } {
    const added: ServicePayloadItem = {};
    for (const entry of this.addedPrefixes.entries()) {
      // for readability
      const sapSubscriptionInstanceIdList = entry[1];
      const prefixSubscriptionId = entry[0];
      sapSubscriptionInstanceIdList.forEach((sid: string) => {
        added[sid] = [...(added[sid] ?? []), prefixSubscriptionId];
      });
    }

    const removed: ServicePayloadItem = {};
    for (const entry of this.removedPrefixes.entries()) {
      // for readability
      const sapSubscriptionInstanceIdList = entry[1];
      const prefixSubscriptionId = entry[0];
      sapSubscriptionInstanceIdList.forEach((sid: string) => {
        removed[sid] = [...(removed[sid] ?? []), prefixSubscriptionId];
      });
    }

    return {
      added,
      removed,
    };
  }

  ngOnInit() {
    this.getSelectedItems();

    // Fix for cdk drag and drop in combination with mat dialog
    const topMargin = document.getElementsByTagName("html")[0].style.top;
    document.getElementsByTagName("html")[0].classList.add("scroll-fix");
    document.getElementsByTagName("app-root")[0].setAttribute("style", "position: relative; top:" + topMargin);
  }

  ngOnDestroy() {
    // Fix for cdk drag and drop in combination with mat dialog
    document.getElementsByTagName("app-root")[0].setAttribute("style", "position: static; top: 0;");
    document.getElementsByTagName("html")[0].classList.remove("scroll-fix");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription instanceof Instance) {
      this.buttonLoading = true;
      this.subscriptionCopy = {
        ...this.subscription,
        _saps: this.subscription._saps.map((s) => ({
          ...s,
          ip_prefixes: (s.ip_prefixes || []).map((p) => Object.assign({}, p)),
        })),
      };
      this.originalSelectedItems = Object.assign(
        {},
        this.subscriptionCopy._saps.map((item) => ({
          ip_prefixes: [...item.ip_prefixes],
        })),
      );

      this.getIpPrefix();
    }
  }

  matchPrefixes(): boolean {
    const prefixesInUse = [];
    this.subscriptionCopy._saps.map((sap) => {
      const sapPrefixes = sap.ip_prefixes.map((prefix) => prefix.subscriptionId);
      prefixesInUse.push(...sapPrefixes);
    });
    const curatedList = new Set(prefixesInUse);
    return prefixesInUse.length === curatedList.size * this.subscription._saps.length;
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  getSelectedItems() {
    if (!this.subscriptionCopy?._saps?.[this.sapIndex]?.ip_prefixes) {
      return;
    }
    this.selectedItems = this.subscriptionCopy._saps[this.sapIndex].ip_prefixes || [];
  }

  expand(i) {
    if (this.expandIndexes.includes(i)) {
      this.expandIndexes = this.expandIndexes.filter((item) => item !== i);
    } else {
      this.expandIndexes.push(i);
    }
  }

  prev() {
    this.currentStep--;
  }

  countNewItems(newItems, i) {
    return newItems.filter((val) => !this.originalSelectedItems[i].ip_prefixes.includes(val)).length;
  }

  removedItems(i) {
    const originalArray = this.originalSelectedItems[i].ip_prefixes;
    const newArray = this.subscriptionCopy?._saps[i].ip_prefixes;

    const difference = originalArray.filter((x) => !newArray.includes(x));
    return difference;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    // test constraints. do nothing if they fail.
    if (!this.constraints()) {
      return;
    }

    const { added, removed } = this.servicePayload;

    this.buttonLoading = true;
    const modify = new ModifyIpPrefixes();
    const action =
      this.subscription.product.tag === "IPBGP" ?
        SelfServiceCommandKeys.ModifyIpPrefixesIpBgp
      : SelfServiceCommandKeys.ModifyIpPrefixesIpStatic;
    const command = new SelfServiceCommand(this.subscriptionCopy.subscriptionId, action);

    for (const sapSubscriptionInstanceId in added) {
      modify.setForInstance(MessagePart.ADD, sapSubscriptionInstanceId, added[sapSubscriptionInstanceId]);
    }

    for (const sapSubscriptionInstanceId in removed) {
      modify.setForInstance(MessagePart.DELETE, sapSubscriptionInstanceId, removed[sapSubscriptionInstanceId]);
    }

    command.payload = modify;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action,
        });
      })
      .catch((err) => {
        this.onError(err);
      });
  }

  isOriginalItem(prefix) {
    if (
      this.originalSelectedItems[this.sapIndex].ip_prefixes.filter((item) => item.ip_prefix.prefix === prefix).length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  isOriginalItemInSap(index: number, prefix: string) {
    return this.originalSelectedItems[index].ip_prefixes.filter((item) => item.ip_prefix.prefix === prefix).length > 0;
  }

  sortedPrefixes(prefix_list: Array<IPPrefixSubscription>): Array<IPPrefixSubscription> {
    // don't touch the original list. sort() will modify the original array.
    const clonedList = prefix_list.map((a) => a);
    // remove the subnet part (the '/26' in 'x.x.x.x/26')
    const removeSubnet = (ip: string) => ip.split(`/`)[0];
    // convert the IP address to its int32 representation to sort
    const ipToInt = (ip: string) =>
      removeSubnet(ip)
        .split(`.`)
        .map((i: string) => Number(i))
        .reduce((r, e) => r * 256 + +e);
    clonedList.sort(
      (a: IPPrefixSubscription, b: IPPrefixSubscription) => ipToInt(a.ip_prefix.prefix) - ipToInt(b.ip_prefix.prefix),
    );
    return clonedList;
  }

  trackAddRemove(event: CdkDragDrop<IPPrefixSubscription[]>) {
    const draggedPrefix = event.previousContainer.data[event.previousIndex];
    const isAdded = event.previousContainer.id === "cdk-drop-list-0" && event.container.id === "cdk-drop-list-1";
    const isRemoved = event.previousContainer.id === "cdk-drop-list-1" && event.container.id === "cdk-drop-list-0";
    const original = this.isOriginalItemInSap(this.sapIndex, draggedPrefix.ip_prefix.prefix);

    const addToMap = (map: Map<PrefixSubscriptionId, Array<SubscriptionInstanceId>>, key: string, value: string) => {
      if (map.has(key)) {
        map.set(key, [...map.get(key), value]);
      } else {
        map.set(key, [value]);
      }
    };

    const removeFromMap = (
      map: Map<PrefixSubscriptionId, Array<SubscriptionInstanceId>>,
      key: string,
      value: string,
    ) => {
      if (!map.has(key)) {
        return;
      }
      map.set(
        key,
        map.get(key).filter((sid) => sid !== value),
      );
    };

    if (!original) {
      if (isAdded) {
        addToMap(this.addedPrefixes, draggedPrefix.subscriptionId, this.currentSapSubscription);
      } else if (isRemoved) {
        removeFromMap(this.addedPrefixes, draggedPrefix.subscriptionId, this.currentSapSubscription);
        // not original, and removed from the 'active' list.
        // so, not a removed prefix, but rather _not an added_ prefix.
        this.addedPrefixes.delete(draggedPrefix.subscriptionId);
      }
    }
    if (original) {
      if (isRemoved) {
        addToMap(this.removedPrefixes, draggedPrefix.subscriptionId, this.currentSapSubscription);
      } else if (isAdded) {
        removeFromMap(this.removedPrefixes, draggedPrefix.subscriptionId, this.currentSapSubscription);
      }
    }
  }

  drop(event: CdkDragDrop<IPPrefixSubscription[]>) {
    if (event.previousContainer === event.container) {
      // just a rearrangement in the same list. No action needed.
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.trackAddRemove(event);
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  prevPort(): void {
    const portsTotal = this.subscriptionCopy?._saps.length;
    if (this.sapIndex === 0) {
      this.sapIndex = portsTotal - 1;
    } else {
      this.sapIndex--;
    }
    this.getSelectedItems();
  }

  nextPort(): void {
    const portsTotal = this.subscriptionCopy?._saps.length;
    if (this.sapIndex === portsTotal - 1) {
      this.sapIndex = 0;
    } else {
      this.sapIndex++;
    }
    this.getSelectedItems();
  }

  setupChoices() {
    const availableFetchedPrefixes = this.fetchedPrefixes
      .map((sub) => ({
        ip_prefix: {
          prefix:
            /* first from ipam */ sub.ipPrefix?.ipam?.prefix ?? /* last resort */ sub.description.split(" ").pop(),
        },
        subscriptionId: sub.subscriptionId,
      }))
      .filter((sub) => {
        const ipPinnenPrefix = "145.98.";
        return !sub.ip_prefix.prefix.startsWith(ipPinnenPrefix);
      });

    if (this.selectedPrefix) {
      const preSelectedPrefix = availableFetchedPrefixes.find(
        (prefix) => prefix.subscriptionId === this.selectedPrefix.subscriptionId,
      );

      this.subscriptionCopy._saps.forEach((sap) => {
        sap.ip_prefixes.push(preSelectedPrefix);
      });
      const sapInstanceIds = this.subscriptionCopy._saps.map((sap) => sap.subscriptionInstanceId);
      this.addedPrefixes.set(preSelectedPrefix.subscriptionId, sapInstanceIds);
    }

    const usedPrefixMapping = this.subscriptionCopy._saps.map((sap) => sap.ip_prefixes);

    this.availablePrefixMapping = usedPrefixMapping.map((sapPrefixes) => {
      const sapPrefixesIds = sapPrefixes.map((prefix) => prefix.subscriptionId);
      return availableFetchedPrefixes.filter((prefix) => !sapPrefixesIds.includes(prefix.subscriptionId));
    });
  }

  async getIpPrefix() {
    this.apollo
      .watchQuery({
        query: SUBSCRIPTIONS_OF_TYPE_QUERY,
        errorPolicy: "all",
        variables: {
          customer: this.subscription.customerId,
          products: ["IP_PREFIX"],
        },
      })
      .valueChanges.subscribe({
        next: (result) => {
          this.fetchedPrefixes = result.data["customer"]["subscriptions"];
          this.setupChoices();
          this.buttonLoading = false;
        },
      });
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = transformError(err.error.detail);
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }

  constraints() {
    let state = true;
    this.subscriptionCopy._saps.map((sap) => {
      if (sap.ip_prefixes.length < 1) {
        state = false;
      }
    });
    return state;
  }
}
