import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { Router } from "@angular/router";
import {
  AggspSubscriptionDetailsType,
  IrbSubscriptionDetailsType,
  LinkMemberDetailsType,
  MscSubscriptionDetailsType,
  ServicePortSubscriptionDetailsType,
  ServiceType,
} from "../../../gql/generated-ingestor";
import { PortTileSap } from "../port-tile/port-tile";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";

@Component({
  selector: "port-visualiser-replicated",
  templateUrl: "port-visualiser.html",
  styleUrls: ["port-visualiser.scss"],
})
export class PortVisualiserReplicated implements OnChanges {
  @Input() unavailable: boolean;
  @Input() barsWillRoute = true;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() subscription:
    | PortTileSap["port"]
    | ServicePortSubscriptionDetailsType
    | MscSubscriptionDetailsType
    | AggspSubscriptionDetailsType
    | IrbSubscriptionDetailsType
    | LinkMemberDetailsType
    | Instance;
  @Input() highlightedSubscriptionId = "";

  public bookingPercentage = 0;
  public amountReserved = 0;
  public serviceStats: any;
  public loadedSubscriptionId: any;

  public service: Instance;

  constructor(
    public auth: AuthService,
    public api: ApiHelper,
    private route: Router,
  ) {}

  hasAmountReserved = (subscription: any): subscription is Instance => subscription.amountReserved !== undefined;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      // @ts-ignore
      this.service = this.subscription;
      this.setBookingPercentage();
    }
  }

  setBookingPercentage() {
    this.bookingPercentage = Math.round((this.subscription.amountReserved / this.subscription.portSpeed) * 100);
  }

  clickBar(service: ServiceType): void {
    if (this.barsWillRoute) {
      getDetailRouterLink(service, service.product.type);
    } else {
      this.onSelected.emit(service.subscriptionId);
      this.highlightedSubscriptionId = service.subscriptionId;
    }
  }
}
