<div>
  <div *ngIf="displayLoadingBanner" class="dialog-loading-banner">
    <span>{{ 'Selfservice.IP.Waiting' | translate }}</span>
  </div>
  <div class="title-flex">
    <h3 class="title-with-icon">
      <svg-icon src="assets/images/icons/port.svg" class="purple"></svg-icon>
      <span *ngIf="service?.product?.productType === 'IP'">{{ 'ServiceElement.PortInternet' | translate }}</span>
      <span *ngIf="!service?.product || ['L2VPN', 'L3VPN'].includes(service?.product.productType)"
        >{{ 'ServiceElement.ConnectedPorts' | translate }}</span
      >
      <span *ngIf="['FW'].includes(service?.product?.productType)"
        >{{ 'ServiceElement.ConnectedNetworks' | translate }}</span
      >
    </h3>
    <div
      *ngIf="auth.isSelfServiceEnabled && service?.product && !service?.firewallEnabled && (service?.product.productType === 'IP')"
    >
      <div
        *ngIf="auth.isCurrentOrganisation(service?.customerId) && auth.hasRole(['Infraverantwoordelijke'])"
        class="overflow-menu"
        (clickOutside)="overflowMenuIPActive = false"
      >
        <button
          class="overflow-menu__button button button--small button--secondary"
          [ngClass]="{ 'active' : overflowMenuIPActive }"
          (click)="overflowMenuIPActive = !overflowMenuIPActive"
        >
          <svg-icon src="assets/images/icons/cog.svg" class="button__icon"></svg-icon>
          <span> {{ 'Global.Modify' | translate }}</span>
        </button>
        <div class="overflow-menu__items">
          <div
            *ngIf="service?.ipRoutingType === 'BGP'"
            class="overflow-menu__item"
            (click)="openSelfserviceDialog('bgp')"
            [matTooltip]="'Selfservice.Tooltip.BGP' | translate"
            [matTooltipPosition]="'above'"
          >
            {{ 'OverflowMenu.ModifyBGP' | translate }}
          </div>
          <div
            *ngIf="activeTab?.item?.ip_prefixes"
            class="overflow-menu__item"
            [matTooltip]="'Selfservice.Tooltip.IPPrefixes' | translate"
            [matTooltipPosition]="'above'"
            (click)="openSelfserviceDialog('ip-prefixes')"
          >
            {{ 'OverflowMenu.ModifyIPP' | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Disable Selfservice -->
    <div *ngIf="auth.isSelfServiceEnabled && showModifyOptions">
      <div
        *ngIf="auth.isCurrentOrganisation(service?.customerId) && auth.hasRole(['Infraverantwoordelijke'])"
        class="overflow-menu"
        (clickOutside)="overflowMenuIPActive = false"
      >
        <button
          class="overflow-menu__button button button--small button--secondary"
          [ngClass]="{ 'active' : overflowMenuIPActive }"
          (click)="overflowMenuIPActive = !overflowMenuIPActive"
        >
          <svg-icon src="assets/images/icons/cog.svg" class="button__icon"></svg-icon>
          <span> {{ 'Global.Modify' | translate }}</span>
        </button>
        <div class="overflow-menu__items">
          <div
            class="overflow-menu__item"
            (click)="openSelfserviceDialog('vlan-l2vpn')"
            [matTooltip]="'Selfservice.Tooltip.Vlan' | translate"
            [matTooltipPosition]="'above'"
          >
            {{ 'OverflowMenu.ModifyVlan' | translate }}
          </div>
          <div
            class="overflow-menu__item"
            (click)="openSelfserviceDialog('add-port')"
            [matTooltip]="'Selfservice.Tooltip.AddPort' | translate"
            [matTooltipPosition]="'above'"
          >
            {{ 'OverflowMenu.AddPort' | translate }}
          </div>
          <div
            class="overflow-menu__item"
            (click)="openSelfserviceDialog('remove-port')"
            [matTooltip]="'Selfservice.Tooltip.RemovePort' | translate"
            [matTooltipPosition]="'above'"
          >
            {{ 'OverflowMenu.RemovePort' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card--no-padding tabs" *ngIf="service?.product && tabs.length > 0">
    <div class="tabs__nav-wrapper">
      <div class="tabs__nav" #tabsnav>
        <div
          class="tabs__nav-item"
          *ngFor="let tab of tabs; let i = index"
          (click)="activeTabIndex = i"
          [ngClass]="{'active': activeTabIndex === i}"
        >
          <div class="tabs__nav-caption">
            <div class="tabs__nav-title">{{ tab.title }}</div>
            <div class="tabs__nav-subtitle">{{ tab.subtitle }}</div>
          </div>
        </div>
        <div class="tabs__controls">
          <loader *ngIf="loading" class="tabs__control tabs__control--loader" [small]="true"></loader>
          <div class="tabs__control" (click)="moveTab(-1)">
            <svg-icon src="assets/images/icons/arrow-small-left.svg"></svg-icon>
          </div>
          <div class="tabs__control" (click)="moveTab(1)">
            <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="tabs__content">
      <div class="tabs__grid" *ngIf="activeTab">
        <div *ngFor="let serviceSummary of activeTab?.content?.serviceSummaries" class="tabs__grid-item">
          <service-summary
            [endpoint]="serviceSummary"
            [parent]="service"
            [service]="serviceSummary.port ? serviceSummary.port : serviceSummary"
            [portId]="serviceSummary.port?.subscriptionId"
            [subscriptionInstanceId]="serviceSummary.subscriptionInstanceId"
            [vlanranges]="serviceSummary.vlanrange ? [serviceSummary.vlanrange] : []"
            [showTraffic]="activeTab.content.showTrafic"
            [showTrafficButton]="(serviceSummary.product?.productType === 'L2VPN' || serviceSummary.product?.productType === 'L3VPN' || serviceSummary.port || serviceSummary.l2Endpoints || serviceSummary.l3Endpoints) ? true : false"
            [forceTrafficBottom]="activeTab.content?.configurationDetails.rows.length > 0"
            style="width: 100%"
          ></service-summary>
        </div>

        <div *ngIf="activeTab.content?.configurationDetails.rows.length > 0" class="tabs__grid-item">
          <div class="tabs__flex-center">
            <h3>{{ activeTab.content.configurationDetails.title }}</h3>
          </div>
          <div class="tabs__table">
            <div *ngFor="let row of activeTab.content.configurationDetails.rows" class="tabs__table-row">
              <div class="tabs__table-col">{{ row.title }}</div>

              <div *ngIf="!row.display || row.display === 'default'" class="tabs__table-col">
                {{ row.value | enabled_or_value}}
              </div>
              <div *ngIf="auth?.isSelfServiceEnabled && row.display === 'bgp-component'" class="tabs__table-col">
                <span
                  *ngIf="auth?.isCurrentOrganisation(service?.customerId) && auth?.hasRole(['Infraverantwoordelijke']) && !service?.firewallEnabled; else readOnly"
                  class="table-properties__flex"
                  [ngClass]="{'table-properties__flex--blue': row.value.bfd === true, 'table-properties__flex--disabled': false }"
                >
                  {{ row.value.bfd | enabled_or_value }}
                  <selfservice-toggle
                    [active]="row.value.bfd === true"
                    [tooltipText]="'Selfservice.Tooltip.BFD' | translate"
                    (click)="openSelfserviceDialog('bfd')"
                  ></selfservice-toggle>
                </span>
                <ng-template #readOnly> {{ row.value.bfd | enabled_or_value }} </ng-template>
              </div>
              <div *ngIf="row.display === 'textbox' && row.value.ip_prefixes" class="tabs__table-col">
                <div class="tabs__overflow-box">
                  <div *ngFor="let ip of row.value.ip_prefixes" class="tabs__overflow-box-item">
                    {{ ip.ip_prefix?.prefix }}
                  </div>
                </div>
              </div>
              <div *ngIf="row.display === 'textbox' && !row.value.ip_prefixes" class="tabs__table-col">
                <div class="tabs__overflow-box">
                  <div *ngFor="let ip of row.value" class="tabs__overflow-box-item">{{ ip }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card--no-padding" *ngIf="!service?.product || tabs.length === 0">
    <loader *ngIf="loading" class="card--loader"></loader>
    <empty-state
      *ngIf="!loading"
      [title]="'ServiceElement.ConnectedNetworks' | translate"
      [icons]="['ip', 'l2vpn']"
    ></empty-state>
  </div>
</div>
