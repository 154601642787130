<section class="cert-mitigation">
  <h3 class="title-with-icon">
    <svg-icon src="assets/images/icons/cert.svg" class="black-medium"></svg-icon>
    <span>{{ 'Cert.MitigationSecurity' | translate }}</span>
  </h3>
  <loader *ngIf="loading" [detailed]="true" [text]="loadingState.asText"></loader>
  <div class="cert-table card card--no-padding">
    <div class="table-wrapper">
      <table matSort matSortActive="description" matSortDirection="desc" (matSortChange)="changeSort($event)">
        <thead>
          <tr>
            <th class="asc" mat-sort-header="description">
              <div>
                <span>{{ 'Prefixes.Table.Description' | translate }}</span>
              </div>
            </th>
            <th class="desc" mat-sort-header="prefix">
              <div>
                <span>IP prefix</span>
              </div>
            </th>
            <th mat-sort-header="version">
              <div>
                <span>{{ 'Prefixes.Table.Version' | translate }}</span>
              </div>
            </th>
            <th>
              <div>
                <span>{{ 'Prefixes.Table.auto-mitigation' | translate }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prefix of sortedData">
            <td>{{ prefix.description }}</td>
            <td>{{ addressFor(prefix.ip_prefix?.prefix) }}</td>
            <td>{{ ipVersionFor(prefix.ip_prefix?.afi) }}</td>
            <td>
              <selfservice-toggle
                *ngIf="auth.isSelfServiceEnabled && auth.isCurrentOrganisation(prefix.customerId)"
                [active]="mitigationState[prefix.subscriptionId] === 'enabled' || false"
                (click)="toggleClick(prefix)"
              ></selfservice-toggle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
