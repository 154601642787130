import { Component, Input, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogSlsComponent } from "../../dialogs/dialog-sls/dialog-sls";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { DialogSendConfigComponent } from "../../dialogs/dialog-send-config/dialog-send-config";
import { DialogTicketsComponent } from "../../../components/dialogs/dialog-tickets/dialog-tickets";

import { SubscriptionMessage } from "../../../helpers/types";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/AuthService";
import { DialogMessageComponent } from "../../dialogs/dialog-message/dialog-message";
import { TranslateService } from "@ngx-translate/core";
import { DialogSelfserviceReplicated } from "../../selfservice-replicated/dialog-selfservice/dialog-selfservice";

@Component({
  selector: "shortcut-menu-replicated",
  templateUrl: "shortcut-menu.html",
  styleUrls: ["shortcut-menu.scss"],
})
export class ShortcutMenuReplicated implements OnInit {
  @Input() subscription;
  @Input() productType: string;
  @Input() messages: Notification[];

  public isAuthenticatedForSelfService = false;
  public selfServiceTokenExpires = 0;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public router: Router,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    // check for a pending result from selfservice-authentication.
    // if strong_pending is found, show the auth dialog with
    // a checkmark indicating auth was successful.
    if (this.auth.hasPendingStrongAction("")) {
      this.auth.clearPendingStrongAction("");
      this.showAuthDialog(true);
    }

    if (this.auth.isAuthenticatedForSelfService()) {
      this.selfServiceTokenExpires = this.auth.selfServiceTokenExpiryTime;
      this.isAuthenticatedForSelfService = true;
    }
  }

  showSlsDialog() {
    const dialogRef = this.dialog.open(DialogSlsComponent, {
      data: this.subscription,
    });
  }

  showAuthDialog(state: boolean = false) {
    this.dialog.open(DialogAuthComponent, { data: { state } });
  }

  startSelfService() {
    // if (!this.auth.isAuthenticatedForSelfService()) {
    //   this.showAuthDialog();
    // } else {
    //   this.showAuthDialog();
    // }
    this.showAuthDialog();
  }

  showWrongRoleMessage() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        title: this.translate.instant("Dialog.Selfservice.RoleError.Title"),
        message: this.translate.instant("Dialog.Selfservice.RoleError.Message", {
          role: this.auth.getRoleNames(),
        }),
      },
    });
  }

  openSelfserviceDialog(type) {
    this.dialog.open(DialogSelfserviceReplicated, {
      data: {
        type,
        subscription: this.subscription,
      },
    });
  }

  openSendConfigDialog() {
    this.dialog.open(DialogSendConfigComponent, {
      data: {
        subscription: this.subscription,
      },
    });
  }

  redirectToMalfunction() {
    this.router.navigate([
      "/storing-en-onderhoud/" + this.subscription.product.productType + "/" + this.subscription.subscriptionId,
    ]);
  }

  openTicketsDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogTicketsComponent, {
      data: {
        serviceMessages: this.messages,
      },
    });
    return false;
  }
}
